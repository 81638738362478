
.nav-bar.container.void-background.has-shadow.is-fluid {
    position: sticky;
    top: 0; /* Required for sticky positioning */
    z-index: 1000; /* Higher than other elements */

    font-weight: 400;
    color: #4a4a4a;
    line-height: 1.5;
    font-size: 1rem;
    box-sizing: inherit;
    align-items: stretch;
    display: flex;
    background-color: #fff;
    min-height: 3.5rem;
    margin: 0px;


    text-align: center;

    width: 100%;
    box-shadow: 0 2px 3px rgba(10,10,10,.1);
    padding-right: 50px;


}

@media (max-width: 768px) {
    .nav-bar.container.void-background.has-shadow.is-fluid {
        display: none;
    }
}
