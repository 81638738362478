.project-title a {
    /* color: #7798CA; */
    /* Change the color when hover */
    text-decoration: underline;
    font-size: 1.5rem;
    /* Add an underline when hover */
    cursor: pointer;
    /* Change the cursor to a pointer when hover */
}

.project-title a:hover {
    color: #02a48c;
    /* Change the color when hover */
    text-decoration: underline;
    /* Add an underline when hover */
    cursor: pointer;
    /* Change the cursor to a pointer when hover */
}

.main {
    text-rendering: optimizeLegibility;
    --rem: 16;
    --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Solid";
    --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Regular";
    --fa-font-light: normal 300 1em/1 "Font Awesome 6 Light";
    --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Thin";
    --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
    --fa-font-sharp-solid: normal 900 1em/1 "Font Awesome 6 Sharp";
    --fa-font-sharp-regular: normal 400 1em/1 "Font Awesome 6 Sharp";
    --fa-font-sharp-light: normal 300 1em/1 "Font Awesome 6 Sharp";
    --fa-font-sharp-thin: normal 100 1em/1 "Font Awesome 6 Sharp";
    --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    color: #4a4a4a;
    line-height: 1.5;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 1rem;
    box-sizing: inherit;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    grid-gap: 2em;
    margin-top: 2em;
    padding-left: 10px;
    padding-right: 10px;
}

.card-repo {

    background-color: #002230;

    padding: 2em;
    border-radius: 10px;
    border: 2px solid black;

    box-shadow: 6px 5px 2px black;

    min-height: 400px;

    margin: auto;
    margin-bottom: 30px;
    position: relative;
}

.card-repo p {
    color: #ffffff;
    text-align: left;
    font-size: 1.3em;
    margin-bottom: 2em;
    line-height: 1.6em;
}

.graph {
    position: absolute; /* Position the graph absolutely within the card */
    right: 0; /* Align to the right */
    bottom: 0; /* Align to the bottom */
    width: 150px; /* Set a fixed width */
    height: 150px; /* Set a fixed height */
    min-width: 100px;
  }
  
  /* This ensures the canvas within the graph div is the same size as the graph div itself */
  .graph canvas {
    width: 100% !important;
    height: 100% !important;
  }

  .entire-thing {
    background-color: #ddedff;
    padding-top: 50px;
    padding-bottom: 42px;
}

.repositories-title {
    background-color: #ddedff;
    color: #04d8ac;
    font-size: 4em;
    font-weight: 500;

    padding-left: -300px;
    text-align: center;
}

.mini-title {
    background-color: #ddedff;
    color: #04d8ac;
    font-size: 3em;
    font-weight: 400;

    /* padding-left: 5%; */
}

@media (max-width: 768px) {
    .main {
        /* all: unset; */
        display:block

    }

    .card-repo {

        padding: 1.5em;
        /* max-width: 100px; */
    }

    .graph {
        /* visibility: hidden; */
    }
}
.show-all-button {
    background-color: #04d8ac;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 20px auto;
    display: block;
    text-align: center;
    width: 50%;
    min-height: 50px;
}
.show-all-button:hover {
    background-color: #02a48c;
}

/* Style for the API fail message container */
.api-fail-message {
  grid-column: 1 / -1; /* Make it span all columns in the grid */
  width: 100%; /* Take up full width */
  text-align: center; /* Center the text */
  padding: 2em 1em; /* Add some padding */
}

/* Style for the API fail message text */
.api-fail-message p {
  font-size: 1.5em; /* Increase text size */
  line-height: 1.6;
  color: #4a4a4a; /* Match your site's text color */
}