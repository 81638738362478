/* Resume.css */
.pdf-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
}

.pdf-iframe {
    width: 100%;
    height: 1000px;
    border: none;
}

.pdf-text {
    margin-top: 1em;
    font-size: 1.3em;
}

.pdf-text a {
    color: #3366cc;
}


.tile.resume{
    padding-top: 50px;
    padding: 50px 15px 100px 15px;
    background-color: inherit;
}

.title.is-2.section-title.resume {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --rem: 16;

    box-sizing: inherit;
    margin: 0;
    padding: 0;
    word-break: break-word;
    line-height: 1.125;
    color: #04d8ac;
    font-weight: 400;
    font-size: 4rem;
    padding-bottom: 15px;
}

