.section {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --rem: 16;
    font-weight: 400;
    line-height: 1.5;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 1em;
    color: black;
    box-sizing: inherit;
    display: block;
    padding: 3rem 1.5rem;
    padding-top: 40px;
    padding-bottom: 3rem;
    min-height: 100vh;
    background-color: inherit;
    overflow-y: hidden;
    position: relative;
    transition: all 0.5s ease-in-out;
}

.tile-is-ancestor {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --rem: 16;
    font-weight: 400;
    line-height: 1.5;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 1em;
    color: black;
    box-sizing: inherit;
    align-items: stretch;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: min-content;
    margin-left: -.75rem;
    margin-right: -.75rem;
    margin-top: -.75rem;
    display: flex;
    margin-bottom: .75rem;
}


.tile-is-child {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --rem: 16;
    font-weight: 400;
    line-height: 1.5;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 1em;
    color: black;
    box-sizing: inherit;
    align-items: stretch;
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: min-content;
    margin: 0!important;
}

.title.is-2.section-title {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --rem: 16;

    box-sizing: inherit;
    margin: 0;
    padding: 0;
    word-break: break-word;
    line-height: 1.125;
    color: #04d8ac;
    font-weight: 400;
    font-size: 4.5rem;
}


.tile.is-ancestor {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --rem: 16;
    font-weight: 400;
    line-height: 1.5;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 1em;
    color: black;
    box-sizing: inherit;
    align-items: stretch;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: min-content;
    margin-left: -.75rem;
    margin-right: -.75rem;
    margin-top: -.75rem;
    display: flex;
    margin-bottom: .75rem;

}

.tile.is-vertical{
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --rem: 16;
    font-weight: 400;
    line-height: 1.5;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 1em;
    color: black;
    box-sizing: inherit;
    align-items: stretch;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: min-content;
    flex-direction: column;
    display: flex;
}

.tile.is-parent.work-elem{
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --rem: 16;
    font-weight: 400;
    line-height: 1.5;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 1em;
    color: black;
    box-sizing: inherit;
    align-items: stretch;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: min-content;
    max-width: 800px;
    margin: auto;
    margin-bottom: 50px;
    padding: .75rem;
    display: flex;
}

.tile.is-child.is-3{
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --rem: 16;
    font-weight: 400;
    line-height: 1.5;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 1em;
    color: black;
    box-sizing: inherit;
    align-items: stretch;
    display: block;
    min-height: min-content;
    margin: 0!important;
    flex: none;
    width: 25%;
}

.image.is-128x128.work-logo{
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --rem: 16;
    font-weight: 400;
    line-height: 1.5;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 1em;
    color: black;
    box-sizing: inherit;
    padding: 0;
    display: block;
    position: relative;
    margin: 10px auto;
    height: 128px;
    width: 128px;
}

.title.is-child {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --rem: 16;
    font-weight: 400;
    line-height: 1.5;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 1em;
    color: black;
    box-sizing: inherit;
    align-items: stretch;
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    min-height: min-content;
    margin: 0!important;
}

.title.is-4{
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --rem: 16;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    word-break: break-word;
    font-weight: 300;
    line-height: 1.125;
    color: black;
    margin-bottom: 1.5rem;
    font-size: 2rem;
}

.subtitle.is-5{
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --rem: 16;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    word-break: break-word;
    line-height: 1.25;
    font-weight: 300;
    color: black;
    margin-bottom: 1.5rem;
    margin-top: -1.25rem;
    font-size: 1.5rem;
}

.subtitle.is-6{
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --rem: 16;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    box-sizing: inherit;
    font-style: inherit;
    word-break: break-word;
    line-height: 1.25;
    color: black;
    font-weight: 300;
    margin-bottom: 1.5rem;
    font-size: 16px;
}

.work-content {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    --rem: 16;
    font-weight: 400;
    line-height: 1.6;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 18px;
    color: black;
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    text-align: justify;
}

@media (max-width: 650px) {
    .tile.is-parent.work-elem {
        flex-direction: column;
        align-items: center;
    }
    .tile.is-child.is-3 {
        width: 100%;
        order: -1; /* This moves the image div above the text div */
    }
    .image.is-128x128.work-logo {
        visibility: visible;
        margin: 10px auto;
        /* width: 50%; */
        height: auto;
    }

}

